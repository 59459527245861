import "./RegistrationList.css";
import Contactforms from "../modules/Contactforms";

const RegistrationList = () => {
  return (
    <>
      <div className="row list">
        <div className="col-md-2"></div>
        <div className="col-md-8 ">
          <ul className="list">
            <li>
              <strong>Admission for Academic Year 2024-2025:</strong> Admissions
              for the upcoming academic year will commence from{" "}
              <strong>October 2023</strong>, providing parents ample time to
              prepare and apply.
            </li>

            <li>
              <strong>Secure Your Spot:</strong> Interested parents are
              encouraged to visit our institute and secure their child's
              admission by paying a nominal admission fee. This ensures your
              child's place in our esteemed institution.
            </li>
            <li>
              <strong>Great Location:</strong> Our school is nestled in the
              serene surroundings of Navanagar, offering an 8-acre campus
              dedicated to educational excellence.
            </li>
            <li>
              <strong> Future Plans:</strong> We are excited to announce our
              plans for a world-class stadium, which will include a 400-meter
              track and grounds for sports like volleyball, Kabaddi, Cricket and
              various other sports.
            </li>
            <li>
              <strong> Holistic Approach:</strong> At ACT Amrita The School, we
              believe that every child is unique and creative. We are dedicated
              to nurturing their individual needs and providing a caring and
              creative environment.
            </li>
            <li>
              <strong> Emphasis on Development:</strong> We place a strong
              emphasis on the emotional, physical, intellectual, and spiritual
              development of each child, ensuring a well-rounded education.
            </li>
            <li>
              <strong> Diverse Curriculum:</strong> Our institute offers a
              diverse and comprehensive curriculum that caters to the individual
              learning needs and interests of our students.
            </li>
            <li>
              <strong> Highly Qualified Faculty:</strong> Our dedicated team of
              educators is committed to providing quality education and
              fostering a nurturing learning environment.
            </li>
            <li>
              <strong> Facilities:</strong> Explore our state-of-the-art
              facilities, including well-equipped classrooms, libraries,
              computer labs, and more, all designed to enhance the learning
              experience.
            </li>
            <li>
              <strong> Join Our Community: </strong>Become a part of the ACT
              Amrita The School community, where we empower young minds to excel
              academically, creatively, and socially.
            </li>
          </ul>
        </div>
        <div className="col-md-2"></div>

        <div className="row">
          <h1 style={{ textAlign: "center" }}>Contact Us</h1>{" "}
          <div className="col-md-4 "></div>
          <div className="col-md-4 space">
            <Contactforms />
          </div>
          <div className="col-md-4 xs-4"></div>
        </div>
      </div>
    </>
  );
};
export default RegistrationList;
