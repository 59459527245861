import "./Clicktoaction.css";
const Clicktoaction = (props) => {
  return (
    <>
      <div className="backgroundcontrol">
        <div className="container1">
          <div className="row justify-content-center text-center">
            <div className="col-md-9 col-12">
              <h4 className="text ">
                Contact us now to learn more about our exceptional academic and
                enriching extracurricular activities.
              </h4>
              <p className="text">
                We're here to assist you every step of the way in becoming a
                part of our vibrant educational community..
              </p>
              <div className="buttoncontrol">
                <div className="d-grid d-md-block">
                  <a href="/contactus" className="btn btn-info">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clicktoaction;
