import React from "react";
import "./PrincipalMessage.css";
const PrincipalMessage = () => {
  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <div className="letter">
        <h1 className="heading">Welcome message from our principal</h1>
        <img
          src="images/principal-profile.jpg"
          alt="Principal"
          className="profile"
        />

        <p className="from">FROM,</p>
        <p>PRINCIPAL</p>
        <p>Dear Students, Parents, and Community Members,</p>
        <p>
          I am delighted to extend a heartfelt welcome to each of you as we
          embark on an exciting journey together at ACT Amrita International
          School, Bagalkot. This inaugural academic year marks the beginning of
          a remarkable adventure in education, growth, and discovery.
        </p>
        <p>
          As the founding principal of ACT Amrita International School, I am
          honored to lead a team of dedicated educators who share a passion for
          cultivating young minds and nurturing individual potential. Our
          mission is clear: to establish a foundation of excellence, innovation,
          and student-centered learning that will shape the future leaders and
          thinkers of tomorrow.
        </p>
        <p>
          At ACT Amrita International School, we are committed to creating a
          vibrant and inclusive learning environment where students are inspired
          to explore, inquire, and develop a deep love for learning. Our
          innovative curriculum is designed to ignite curiosity, foster critical
          thinking, and promote hands-on experiences that will empower students
          to become active participants in their education.
        </p>
        <p>
          As we embark on this inaugural academic year, we are excited to
          introduce a range of engaging programs, extracurricular activities,
          and opportunities for personal growth. Our newly launched school
          website will serve as a hub of information, keeping you updated on
          school events, curriculum highlights, and important announcements.
        </p>
        <p>
          We encourage parents to actively engage in their child's educational
          journey, partnering with us to create a strong support network that
          nurtures academic success and personal development. Your involvement
          will play a pivotal role in shaping the vibrant spirit of our school
          community.
        </p>
        <p>
          To our enthusiastic students, I encourage you to approach this year
          with an open heart and a thirst for knowledge. Your contributions,
          ideas, and aspirations will shape the very essence of ACT Amrita
          International School, and we are excited to witness your growth and
          achievements.
        </p>
        <p>
          I extend my deepest gratitude to our parents, community members, and
          dedicated staff who have tirelessly worked to bring ACT Amrita
          International School to life. Your commitment and support have laid
          the foundation for a bright future.
        </p>
        <p>
          As we raise the curtain on this inaugural academic year, let us stand
          united in our pursuit of educational excellence, innovation, and
          community engagement. Together, we will create a legacy that
          generations to come will be proud of.
        </p>
        <p>
          Welcome to ACT Amrita International School! Here's to an extraordinary
          first year and the promising years that lie ahead.
        </p>
      </div>
      <p>Warmest regards,</p>
      <p>RAVEENDRA HULYAL</p>
      <p>Founding Principal</p>
      <p>ACT Amrita International School</p>
    </div>
  );
};

export default PrincipalMessage;
