import "./Subject.css";
const Subject = () => {
  return (
    <>
      <div className="container text-center">
        <div className="multitilesheader">
          <h1 className="sepl-primary-dark mt-3">SUBJECTS</h1>
        </div>
        <div className="row align-items-start mb-5">
          <div className="col-4 col-md-4">
            <img src="/images/blackboard.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 ">Maths</h6>
          </div>
          <div className="col-4 col-md-4">
            <img src="/images/flask.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 ">Science</h6>
          </div>
          <div className="col-4 col-md-4">
            <img src="/images/text-books.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3  ">Languages</h6>
          </div>
        </div>
        <div className="row align-items-start mb-2 mb-md-0">
          <div className="col-4 col-md-4">
            <img src="/images/eco-friendly.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 growth-heading">
              Environmental Science
            </h6>
          </div>
          <div className="col-4 col-md-4">
            <img src="/images/computer.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 growth-heading">
              Computer Science
            </h6>
          </div>
          <div className="col-4 col-md-4">
            <img src="/images/english.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 growth-heading">English</h6>
          </div>
        </div>
      </div>
    </>
  );
};
export default Subject;
