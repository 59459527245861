import "./Dualcolinfo.css";
const Dualcolinfo = (props) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>{props.data.title}</h1>
            <p>
              {props.data.content}
            </p>
          </div>
          <div className="col">
            <h1>{props.data.lead}</h1>
            <div className="image">
              <img
                src={props.data.imagesrc}
                width="500"
                height="350"
                alt="act journey so far infographic"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dualcolinfo;
