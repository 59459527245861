import "./Accordstyle.css";
const Faqs = (props) => {
  return (
    <>
      <div className="container w-75 pt-3">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                1. What courses are currently offered at ACT Amrita
                International School?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                At present, we offer the State syllabus for classes from 1 -7 .
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                2. What curriculum will be available at ACT Amrita International
                School in the academic year 2024?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Starting from the next academic year 2024, we will offer the
                curriculum from Class 1 to Class 10.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingfour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefour"
                aria-expanded="false"
                aria-controls="collapsefour"
              >
                3. What are the key focus areas of ACT Amrita International
                School's education approach?
              </button>
            </h2>
            <div
              id="collapsefour"
              className="accordion-collapse collapse"
              aria-labelledby="headingfour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We are committed to providing individual attention to each
                student, delivering skill-based education, inculcating
                values-based education, and emphasizing LSWR (Listening,
                Speaking, Reading, and Writing) skills development.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingfive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefive"
                aria-expanded="false"
                aria-controls="collapsefive"
              >
                4. What kind of training do the teachers at ACT Amrita
                International School possess?
              </button>
            </h2>
            <div
              id="collapsefive"
              className="accordion-collapse collapse"
              aria-labelledby="headingfive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                All our teachers possess extensive experience and have undergone
                specialized FST (Finishing School for Teachers) training
                conducted by ACT.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading6">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse6"
                aria-expanded="false"
                aria-controls="collapse6"
              >
                5. Can you tell us about the infrastructure of the school?
              </button>
            </h2>
            <div
              id="collapse6"
              className="accordion-collapse collapse"
              aria-labelledby="heading6"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We are proud to announce that we are building a futuristic 400m
                track field within our 8-acre campus. This track field will also
                house a hockey field and a cricket ground, providing ample
                opportunities for students' sports and fitness activities.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading7">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse7"
                aria-expanded="false"
                aria-controls="collapse7"
              >
                6. Who is the collaboration partner for ACT Amrita International
                School?
              </button>
            </h2>
            <div
              id="collapse7"
              className="accordion-collapse collapse"
              aria-labelledby="heading7"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                The school is established through a collaboration between Dr.
                Gururaj Karajagi sir's ACT and VPM (Sakri high school,
                Bagalkot).
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading8">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse8"
                aria-expanded="false"
                aria-controls="collapse8"
              >
                7. What is the role of ACT in the field of education?
              </button>
            </h2>
            <div
              id="collapse8"
              className="accordion-collapse collapse"
              aria-labelledby="heading8"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                ACT is a pioneering educational body that has consistently
                played a significant role in providing pre-service and
                in-service training to teachers at all levels, contributing to
                the growth and development of education.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading9">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse9"
                aria-expanded="false"
                aria-controls="collapse9"
              >
                8. How can I apply for admission to ACT Amrita International
                School?
              </button>
            </h2>
            <div
              id="collapse9"
              className="accordion-collapse collapse"
              aria-labelledby="heading9"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Please visit our website or contact the school administration
                office for information on the admission process, including
                application forms, eligibility criteria, and important dates.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading10">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse10"
                aria-expanded="false"
                aria-controls="collapse10"
              >
                9. Will the school provide transportation facilities for
                students?
              </button>
            </h2>
            <div
              id="collapse10"
              className="accordion-collapse collapse"
              aria-labelledby="heading10"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, the school will offer transportation facilities for
                students. We prioritize the safety and comfort of our students
                during their commute to and from the school.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading11">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse11"
                aria-expanded="false"
                aria-controls="collapse11"
              >
                10. Does ACT Amrita International School offer any
                extracurricular activities?
              </button>
            </h2>
            <div
              id="collapse11"
              className="accordion-collapse collapse"
              aria-labelledby="heading11"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, we offer a wide range of extracurricular activities,
                including sports, arts, music, dance, and various clubs to help
                students explore their interests and talents.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading12">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse12"
                aria-expanded="false"
                aria-controls="collapse12"
              >
                11. How can parents stay updated about their child's progress at
                ACT Amrita International School?
              </button>
            </h2>
            <div
              id="collapse12"
              className="accordion-collapse collapse"
              aria-labelledby="heading12"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We believe in maintaining a strong parent-school partnership.
                Parents will receive regular updates on their child's academic
                progress, attendance, and other important information through
                periodic parent-teacher meetings.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="heading13">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse13"
                aria-expanded="false"
                aria-controls="collapse13"
              >
                12. How can I contact ACT Amrita International School for
                further inquiries?
              </button>
            </h2>
            <div
              id="collapse13"
              className="accordion-collapse collapse"
              aria-labelledby="heading13"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                You can reach out to us through the contact information provided
                on our website or visit the school in person during working
                hours. Our staff will be more than happy to assist you with any
                inquiries you may have.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
