import Clicktoaction from "../modules/Clicktoaction";
import Accordfaq from "../modules/Accordfaq";
import Playground from "../modules/Playground";
import Heromoduleoneimage from "../modules/Heromoduleoneimage";
import Videodualcolumn from "../modules/Videodualcolumn";
import Multitiles from "../modules/Multitiles";
import Seo from "../global/Seo";
import Dualcoloums from "../modules/Dualcoloums";

const objfstd = {
  title: "Teachers are FST-trained",
  lead: "",
  imagesrc: "/images/fst.jpg",
  content:
    " All our teachers possess extensive experience and have undergone FST (<strong>Finishing School for Teachers</strong>) training by ACT, ensuring a high standard of pedagogy and professionaldevelopment. This comprehensive approach equips them with thenecessary skills to foster a dynamic and enriching learningenvironment for our students.FST is not a replicate of B.Ed program but complements and reinforces the classroom teaching and learning processes techniques and pedagogy.",
  buttonreqd: false,
  buttonsrc: "",
  rightImg: "",
};

const objSeo = {
  title:
    "ACT Amrita International School: Why Act-AIS- Innovative Education with Values",
  keywords:
    "Why actais, Indian aesthetics, modern technology, education, values, creative teaching techniques, NEP integration, community projects",
  pathSlug: "why-aais",
  description:
    "Experience a distinctive educational approach that merges Indian aesthetics with modern tech. Discover innovative teaching methods embedding core values. Teachers receive regular training in NEP integration and progressive techniques. Empowered students initiate community projects. Embrace tradition and innovation for holistic growth.",
};

const Whyaais = () => {
  // Replace the class with "className"
  return (
    <>
      <Seo data={objSeo} />
      <Heromoduleoneimage></Heromoduleoneimage>
      <Multitiles></Multitiles>
      <Videodualcolumn></Videodualcolumn>
      <Playground></Playground>
      {/* <Fstdualcolumn></Fstdualcolumn> */}
      <Dualcoloums data={objfstd} />
      <Accordfaq></Accordfaq>
      <Clicktoaction></Clicktoaction>
    </>
  );
};

export default Whyaais;
