import React, { useState } from "react";
export default function Contactforms() {
  return (
    <>
      <div className="card mt-2 mx-auto p-4 bg-light">
        {/* <form onSubmit={handleOnSubmit}> */}
        <form action="https://formbold.com/s/9B2ym" method="post">
          <div className="mb-3 mt-3 w-75 formpadding">
            <input
              type="Name"
              className="form-control"
              id="Name"
              placeholder="Enter Name"
              name="Name"
            ></input>
          </div>
          <div className="mb-3 mt-3 w-75 formpadding">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
              name="Email"
            ></input>
          </div>
          <div className="mb-3 mt-3 w-75 formpadding">
            <input
              type="Phone number"
              className="form-control"
              id="pnum"
              placeholder="Enter phone number"
              name="Phone Number"
            ></input>
          </div>

          <div className="form-floating">
            <div className="mmb-3 mt-3 w-75 formpadding">
              <textarea
                className="form-control"
                name="feedback"
                placeholder="Type your message"
                id="floatingTextarea2"
              ></textarea>
            </div>

            <div className="mmb-3 mt-3 w-75 formpadding">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
