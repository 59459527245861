import React, { useEffect } from "react";
const Promo = () => {
  useEffect(() => {
    //Trigger the modal after the page has loaded
    const modalTriggerbutton = document.getElementById("modalTriggerButton");
    if (modalTriggerbutton) {
      modalTriggerbutton.click();
    }
  }, []);
  return (
    <>
      {" "}
      {/*Add an id to the button to make it easily accessible*/}
      <button
        id="modalTriggerButton"
        type="button"
        className="d-none btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        sytle={{ display: "none" }}
        //hide the button
      ></button>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1>
                <b>Admissions Open!</b>
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                id="carouselExampleFade"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      src="images/promo/promo20251.jpg"
                      class="d-block w-100"
                      alt="one"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="images/promo/promo20252.jpg"
                      class="d-block w-100"
                      alt="two"
                    />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleFade"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleFade"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Promo;
