import React from "react";
import MultiMediaGallery from "../modules/multimedia/MultiMediaGallery";
import Seo from "../global/Seo";

const objSeo = {
  title:
    "ACT Amrita International School: Media Gallery - Exploring Our Journey",
  keywords:
    " Media gallery, images, videos, institution journey, program, education,ACT Amrita international school, Bagalkot",
  pathSlug: "multimedia",
  description:
    " From classroom interactions to events that celebrate achievements, our media gallery offers a glimpse into the enriching experiences that define our Programs",
};
const MultiMedia = () => {
  return (
    <>
      <Seo data={objSeo} />

      <MultiMediaGallery></MultiMediaGallery>
    </>
  );
};

export default MultiMedia;
