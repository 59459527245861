const multitiles = (props) => {
  return (
    <>
      <span className="border border-primary">
        <div className="container text-center">
          <div className="multitilesheader">
            <h1 className="sepl-primary-dark ">
              ABOUT ACT Amrita INTERNATIONAL SCHOOL
            </h1>
          </div>

          <div className="row align-items-start">
            <div className="col-md">
              <img
                src="/images/Multitiles/individual.png"
                width="65"
                height="65"
              ></img>
              <h5 className="sepl-primary-dark "> Individual Attention</h5>

              <p style={{ textAlign: "justify" }}>
                At our school, we prioritize giving individual attention to each
                student, fostering a nurturing environment where every learner's
                unique strengths and needs are acknowledged and supported. Every
                day, we have a 'Prep Hour' after regular school hours.
              </p>
            </div>
            <div className="col-md">
              <img
                src="/images/Multitiles/skillbased.png"
                width="65"
                height="65"
              ></img>
              <h5 className="sepl-primary-dark ">Skill Based education</h5>
              <p style={{ textAlign: "justify" }}>
                Today’s child is tomorrow’s hope and the builder of a successful
                society and nation.Skill-based education focuses on equipping
                students with practical knowledge and abilities that are
                relevant to real-world challenges, with a focus on Personal
                Development.
              </p>
            </div>
            <div className="col-md">
              <img
                src="/images/Multitiles/valuebased.png"
                width="65"
                height="65"
              ></img>
              <h5 className="sepl-primary-dark "> Values Based Education</h5>
              <p style={{ textAlign: "justify" }}>
                We teach core principles and ethics in students, nurturing their
                character and guiding them to become compassionate, responsible,
                and morally conscious individuals. Every Wednesday, we recite
                one Bhagavad Gita shloka along with its meanings.
              </p>
            </div>
            <div className="col-md">
              <img
                src="/images/Multitiles/lsrwcolor.png"
                width="65"
                height="65"
              ></img>
              <h5 className="sepl-primary-dark ">LSRW </h5>
              <p style={{ textAlign: "justify" }}>
                LSRW stands for Listening, Speaking, Reading, and Writing which
                are the 4 essential language skills that play a crucial role in
                effective communication . We focus on nurturing these skills to
                our children when they grow up have the ability to interact in
                various linguistic contexts.
              </p>
            </div>
          </div>
        </div>
      </span>
    </>
  );
};

export default multitiles;
