import "./Accordstyle.css";
const Accordfaq = (props) => {
  return (
    <div className="container py-3">
      <div className="row">
        <div className="col-sm">
          <h1>How we're different</h1>
          <img
            src="./images/different.jpg"
            className="seplimg img-fluid d-flex justify-content-center "
            alt="ACT Amrita International School"
          />
        </div>
        <div className="col-sm">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <b>Happy Classroom</b>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Our classrooms are a beacon of happiness, where students find
                  inspiration, laughter, and the joy of learning, creating an
                  environment that nurtures their passion for knowledge and
                  exploration.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <b> Student-centric approach to learning</b>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  We put students at the heart of their educational journey,
                  tailoring our teaching methods to suit individual needs,
                  interests, and aspirations, fostering a sense of ownership and
                  curiosity in every learner.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <b>Well-trained teachers to implement NEP</b>
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Our expert educators undergo comprehensive training to
                  seamlessly integrate the National Education Policy (NEP) into
                  their teaching practices, ensuring a transformative and
                  future-focused learning experience for all students.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <b>Creative teaching model</b>
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Embracing innovation and creativity, our teaching model goes
                  beyond conventional boundaries, utilizing interactive and
                  hands-on approaches to stimulate students' imagination,
                  critical thinking, and problem-solving skills.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <b>Mix of Indian aesthetics and modern technology</b>
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  At our school, we strike a harmonious balance between Indian
                  aesthetics and cutting-edge technology, creating a dynamic
                  learning environment that honors tradition while preparing
                  students for a rapidly evolving world.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Accordfaq;
