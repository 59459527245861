import { useEffect } from "react";
import "./Heromoduletwocol.css";
const Heromoduletwocol = () => {
  useEffect(() => {
    var myCarousel = document.getElementById("carouselExampleIndicators");
    // var carousel = new bootstrap.Carousel(myCarousel);
    // carousel.interval = 1000;

    myCarousel.addEventListener("slide.bs.carousel", function (slide) {
      var currentcaption = document.getElementsByClassName(
        "seplcaption" + slide.from
      );
      var nextcaption = document.getElementsByClassName(
        "seplcaption" + slide.to
      );
      currentcaption[0].classList.add("d-none");
      nextcaption[0].classList.remove("d-none");
    });
  }, []);

  return (
    <>
      <div className="container-fluid py-4 bg">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators carousel-indicator">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active carouselbutton"
              aria-current="true"
              aria-label="Slide 1"
            >
              1
            </button>
            <button
              type="button"
              className="carouselbutton"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            >
              2
            </button>
            <button
              className="carouselbutton"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            >
              3
            </button>
          </div>

          <div className="row stack">
            <div className="col-md-4 p-0 ">
              <div className="seplcaption ">
                <div className="seplcaption0 seplcaptionitem ps-2 ">
                  <div>
                    {" "}
                    <h1> ACT Amrita International School </h1>
                  </div>
                  <p className="caption-description">
                    Goal to create enlightened and empowered young men and women
                    for whom success comes naturally
                  </p>
                </div>
                <div className="seplcaption1 seplcaptionitem ps-2 d-none">
                  <div>
                    {" "}
                    <h1>
                      ACT Amrita International School - Individual Attention
                    </h1>
                  </div>

                  <p className="caption-description">
                    Nurturing Brilliance, One Student at a Time - Where
                    Individual Attention Leads to Infinite Possibilities!
                  </p>
                </div>
                <div className="seplcaption2 seplcaptionitem ps-2 d-none">
                  <div>
                    <h1>
                      {" "}
                      ACT Amrita International School- Skill based leaning
                    </h1>
                  </div>

                  <p className="caption-description">
                    Unlocking Potential, One Skill at a Time - Empowering Future
                    Innovators through Skill-Based Education!.
                  </p>
                </div>
              </div>
            </div>
            <div className=" col-md-8 p-0">
              <div className="carousel-inner hero-image-section">
                <div className="carousel-item active hero-images">
                  <img
                    src="images/homemodule/act-amrita-building.jpg"
                    className="d-block w-100 hero-image"
                    alt="..."
                  />
                </div>
                <div className="carousel-item hero-images">
                  <img
                    src="images/homemodule/heromodule1.jpg"
                    className="d-block w-100 hero-image"
                    alt="..."
                  />
                </div>
                <div className="carousel-item hero-images">
                  <img
                    src="images/homemodule/hero2.jpg"
                    className="d-block w-100 hero-image"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Heromoduletwocol;
