import "./HeromoduleVideo.css";
const HeromoduleVideo = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <div className="video-container">
              <video
                src="/images/testvideo1.mp4"
                autoPlay
                muted
                loop
                className="hero-video"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeromoduleVideo;
