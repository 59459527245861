import React from "react";
import "./Textmodule.css";
const ContentLayout = () => {
  return (
    <div className="content-container textmodule">
      <div className="row">
        <div className="col-12 text-justify">
          <h1>
            <strong>Program Overview</strong>
          </h1>
          <p>
            Unlocking Excellence in Education Welcome to our Program, where
            learning takes on a dynamic and comprehensive form, empowering
            students to thrive academically, personally, and socially. As an
            esteemed educational institute, we are committed to providing a
            top-notch curriculum that fosters critical thinking, creativity, and
            holistic development.
          </p>
        </div>
        <div className="col-md-6 text-justify">
          <h5>
            <strong>Rigorous Curriculum</strong>
          </h5>
          <p>
            Our program offers a well-structured curriculum designed to instill
            a strong foundation across subjects such as Mathematics, Science,
            Social Studies, Languages, and more. We integrate real-world
            applications to encourage practical learning.
          </p>
        </div>
        <div className="col-md-6 text-justify">
          <h5>
            <strong>Assessment for Growth</strong>
          </h5>
          <p>
            Embracing the assessment model, we blend continuous evaluation with
            comprehensive examination to gauge students' progress effectively.
            Our focus is not just on grades, but on nurturing a deep
            understanding of concepts.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 text-justify">
          <h5>
            <strong>Innovative Teaching</strong>
          </h5>
          <p>
            Our dedicated educators employ modern pedagogical techniques that
            cater to diverse learning styles, ensuring engagement and retention.
            Interactive sessions, hands-on experiments, and technology
            integration are our hallmarks.
          </p>
        </div>
        <div className="col-md-6 text-justify">
          <h5>
            <strong>Skill Enhancement</strong>
          </h5>
          <p>
            Beyond academics, our program promotes skill development,
            encompassing communication, critical thinking, problem-solving, and
            teamwork. We prepare students to excel in an ever-evolving global
            landscape.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 text-justify">
          <h5>
            <strong>Holistic Approach</strong>
          </h5>
          <p>
            Co-curricular activities, sports, arts, and community involvement
            are integral parts of our program. We believe in nurturing
            well-rounded individuals capable of making meaningful contributions
            to society.
          </p>
        </div>
        <div className="col-md-6 text-justify">
          <h5>
            <strong>Supportive Environment</strong>
          </h5>
          <p>
            Our teachers act as mentors, providing guidance during and after
            school hours. We encourage open communication between students,
            parents, and educators to ensure a seamless learning journey.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContentLayout;
