import React from "react";
import HeroModuleRegistration from "./HeroModuleRegistration";
import TextOnly from "./TextOnly";
import "./ColumnModule.css";
const ColumnModule = () => {
  return (
    <div>
      <HeroModuleRegistration imagesrc="images/homemodule/assembly.jpg" />
      <TextOnly
        title="AboutUs"
        description="At ACT Amrita International School, we are more than an educational institution; we are a community dedicated to nurturing the unique potential within each student. Our unwavering commitment to individual attention ensures that every learner receives personalized support on their educational journey. We empower our students to thrive academically and develop the character and skills needed for success in a dynamic world."
      />
      <div className="container " style={{ maxWidth: "100%" }}>
        <div className="row">
          <div className="col-md-6">
            <div class="card">
              <img
                class="card-img-top"
                src="images/complab3.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title text-center">
                  Why ACT Amrita International School
                </h5>
                <p class="card-text " style={{ textAlign: "justify" }}>
                  At ACT Amrita International School, we stand out as a
                  preferred choice because we prioritize individual attention,
                  fostering skill-based education, and instilling core values,
                  all while emphasizing the crucial Language Skills of
                  Listening, Speaking, Reading, and Writing (LSRW) to nurture
                  well-rounded learners.
                </p>
                <a href="/why-aais" class="btn btn-primary">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div class="card">
              <img
                class="card-img-top"
                src="/images/actvpm.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title text-center">
                  The Synergy of ACT and VPM Trust
                </h5>
                <p class="card-text" style={{ textAlign: "justify" }}>
                  The VPM Trust, a steadfast symbol of educational empowerment,
                  has joined forces with ACT, a pioneering force in educational
                  consultancy in India. Together, these two trailblazing
                  organizations have combined their expertise to establish a
                  remarkable institution in Bagalkot - The ACT Amrita
                  International School.
                </p>
                <a href="/act-vpm-synergy" class="btn btn-primary">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-6">
            <div class="card">
              <img
                class="card-img-top"
                src="images/principal-profile.jpg"
                alt="Card image cap"
                style={{ width: "100%", height: "46vh" }}
              />
              <div class="card-body">
                <h5 class="card-title text-center">
                  Welcome message from our principal
                </h5>
                <p class="card-text" style={{ textAlign: "justify" }}>
                  I am delighted to extend a heartfelt welcome to each of you as
                  we embark on an exciting journey together at ACT Amrita
                  International School, Bagalkot. This inaugural academic year
                  marks the beginning of a remarkable adventure in education,
                  growth, and discovery.
                </p>
                <a
                  href="/welcome-message-from-our-principal"
                  class="btn btn-primary"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div class="card">
              <img
                class="card-img-top"
                src="images/interactionwithkids.jpg"
                alt="Card image cap"
                style={{ width: "100%" }}
              />
              <div class="card-body">
                <h5 class="card-title text-center">Leadership Team</h5>
                <p class="card-text" style={{ textAlign: "justify" }}>
                  Our leadership team at ACT Amrita International School
                  comprises accomplished individuals who are deeply committed to
                  shaping the future of education.
                </p>
                <a href="/leadershipteam" class="btn btn-primary">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ColumnModule;
