import Modal from "./Modal";
import "./Montagepics.css";
const Montagepics = (props) => {
  return (
    <>
      {/* <div className="container text-center"> */}
      <div className="row  ">
        <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
          <div className="card bg-success text-white text-center mb-4">
            <div className="card-body">
              <h5 className="card-title text-center">
                Panel title that wraps to a new line
              </h5>

              <p>
                <img
                  src="images/Montageinuse/new1.jpg"
                  alt="news1"
                  height="300px"
                  width="300px"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  data-bs-imgsrc="images/Montageinuse/new1.jpg"
                />
              </p>
            </div>
          </div>
          <div className="card bg-info text-white text-end mb-4">
            <div className="card-body">
              <h5 className="card-title text-center">Panel title</h5>

              <p>
                <img
                  src="images/Montageinuse/new3.jpg"
                  alt="news1"
                  width="300px"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  data-bs-imgsrc="images/Montageinuse/new3.jpg"
                />
              </p>
            </div>
          </div>
          <div className="card bg-warning text-white mb-4">
            <div className="card-body">
              <h5 className="card-title text-center">Panel title</h5>

              <p>
                <img
                  src="images/Montageinuse/new4.jpg"
                  alt="news1"
                  width="300px"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  data-bs-imgsrc="images/Montageinuse/new4.jpg"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-4 mb-lg-0">
          <div className="card bg-primary text-white mb-4 text-end">
            <div className="card-body">
              <h5 className="card-title text-center">Panel title</h5>

              <img
                src="images/Montageinuse/new5.jpg"
                alt="news1"
                width="300px"
                height="400px"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-imgsrc="images/Montageinuse/new5.jpg"
              />
            </div>
          </div>
          <div className="card bg-danger text-white text-center my-4">
            <div className="card-body">
              <h5 className="card-title text-center">Panel title</h5>

              <p>
                <img
                  src="images/Montageinuse/new2.jpg"
                  alt="news1"
                  width="300px"
                  height="200px"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  data-bs-imgsrc="images/Montageinuse/new2.jpg"
                />
              </p>
            </div>
          </div>
          <div className="card bg-success text-white mb-4">
            <div className="card-body">
              <h5 className="card-title text-center">
                Panel title that wraps to a new line
              </h5>

              <img
                src="images/Montageinuse/new6.jpg"
                alt="news1"
                width="300px"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-imgsrc="images/Montageinuse/new6.jpg"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-4 mb-lg-0">
          <div className="card bg-warning text-white text-center mb-4">
            <div className="card-body">
              <h5 className="card-title text-center">Panel title</h5>

              <img
                src="images/Montageinuse/new7.jpg"
                alt="news1"
                width="300px"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-imgsrc="images/Montageinuse/new7.jpg"
              />
            </div>
          </div>
          <div className="card bg-dark text-white mb-4">
            <div className="card-body">
              <h5 className="card-title text-center">Panel title</h5>

              <img
                src="images/Montageinuse/new8.png"
                alt="news1"
                width="300px"
                height="100px"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-imgsrc="images/Montageinuse/new8.jpg"
              />
            </div>
          </div>
          <div className="card bg-secondary text-white text-end mb-4">
            <div className="card-body">
              <h5 className="card-title text-center">Panel title</h5>

              <img
                src="images/Montageinuse/new9.jpg"
                alt="news1"
                width="300px"
                height="100px"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-imgsrc="images/Montageinuse/new9.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <Modal></Modal>
    </>
  );
};
export default Montagepics;
