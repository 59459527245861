import React from "react";

const MultiMediaCarousel = (props) => {
  return (
    <>
      <div
        id={props.data.carouselElementId}
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {props.data.src.map((x, y) => {
            let active = "active";
            if (y !== 0) {
              active = "";
            }
            return (
              <button
                key={x.imgId}
                type="button"
                data-bs-target={`#${props.data.carouselElementId}`}
                data-bs-slide-to={y}
                className={`${active} active-bg`}
              ></button>
            );
          })}
        </div>

        <div className="carousel-inner">
          {props.data.src.map((imageaddress, index) => {
            let active = "active";
            if (index !== 0) {
              active = "";
            }
            return (
              <div
                key={imageaddress.imgId}
                className={`carousel-item ${active}`}

              >
                <img
                  src={imageaddress.path}
                  alt="Los Angeles"
                  className="d-block"
                  style={{ width: "100%" }}
                />
                <div className="carousel-caption">
                  {/* <p className="text-dark d-flex justify-content-center">
                    {props.data.content}
                  </p> */}
                </div>
              </div>
            );
          })}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={`#${props.data.carouselElementId}`}
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon"></span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={`#${props.data.carouselElementId}`}
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon"></span>
        </button>
      </div>
    </>
  );
};

export default MultiMediaCarousel;
