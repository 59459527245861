import { useReducer } from "react";
import MultiMediaCarousel from "./MultiMediaCarousel";
import "./MultiMediaGallery.css";
const obj = [
  {
    id: 1,
    galleryId: "G1",
    galleryName: "Infrastructure",
    galleryProfile: "images/multimedia/infrastructure/pic4.jpg",
    src: [
      {
        path: "images/multimedia/infrastructure/pic1.jpg",
        imgId: "101A",
      },
      {
        path: "images/multimedia/infrastructure/pic4.jpg",
        imgId: "101B",
      },
      {
        path: "images/multimedia/infrastructure/pic2.jpg",
        imgId: "101C",
      },
      {
        path: "images/multimedia/infrastructure/pic3.jpg",
        imgId: "101D",
      },
      {
        path: "images/multimedia/infrastructure/pic5.jpg",
        imgId: "101E",
      },
    ],
    carouselElementId: "multimediagallery",
    content: "Infrastructure",
  },
  {
    id: 2,
    galleryId: "G2",
    galleryName: "Class Activites",
    galleryProfile: "images/multimedia/classactivites/pic7.jpg",
    src: [
      {
        path: "images/multimedia/classactivites/pic4.jpg",
        imgId: "102A",
      },
      {
        path: "images/multimedia/classactivites/pic5.jpg",
        imgId: "102B",
      },
      {
        path: "images/multimedia/classactivites/pic6.jpg",
        imgId: "102C",
      },
      {
        path: "images/multimedia/classactivites/pic8.jpg",
        imgId: "102D",
      },
      {
        path: "images/multimedia/classactivites/pic7.jpg",
        imgId: "102E",
      },
      {
        path: "images/multimedia/classactivites/pic9.jpg",
        imgId: "102F",
      },
    ],
    carouselElementId: "multimediagallery",
    content: "Class Activites",
  },
  {
    id: 3,
    galleryId: "G3",
    galleryName: "Inter School Flok-Dance Competation",
    galleryProfile: "images/multimedia/interschoolflokdance/pic1.jpg",
    src: [
      {
        path: "images/multimedia/interschoolflokdance/pic2.jpg",
        imgId: "103A",
      },
      {
        path: "images/multimedia/interschoolflokdance/pic8.jpg",
        imgId: "103B",
      },
      {
        path: "images/multimedia/interschoolflokdance/pic4.jpg",
        imgId: "103C",
      },
      {
        path: "images/multimedia/interschoolflokdance/pic5.jpg",
        imgId: "103D",
      },
      {
        path: "images/multimedia/interschoolflokdance/pic7.jpg",
        imgId: "103E",
      },
      {
        path: "images/multimedia/interschoolflokdance/pic11.jpg",
        imgId: "103F",
      },
    ],
    carouselElementId: "multimediagallery",
    content: "Inter School Flok-Dance Competation",
  },
  {
    id: 4,
    galleryId: "G4",
    galleryName: "English Hub",
    galleryProfile: "images/multimedia/englishhub/pic7.jpeg",
    src: [
      { path: "images/multimedia/englishhub/pic1.jpeg", imgId: "104A" },
      { path: "images/multimedia/englishhub/pic2.jpeg", imgId: "104B" },
      { path: "images/multimedia/englishhub/pic3.jpeg", imgId: "104C" },
      { path: "images/multimedia/englishhub/pic6.jpeg", imgId: "104D" },
      { path: "images/multimedia/englishhub/pic7.jpeg", imgId: "104E" },
      { path: "images/multimedia/englishhub/pic8.jpeg", imgId: "104F" },
      { path: "images/multimedia/englishhub/pic9.jpeg", imgId: "104G" },
      { path: "images/multimedia/englishhub/pic10.jpeg", imgId: "104H" },
      { path: "images/multimedia/englishhub/pic11.jpeg", imgId: "104I" },
    ],
    carouselElementId: "multimediagallery",
    content: "English Hub",
  },
  {
    id: 5,
    galleryId: "G5",
    galleryName: "Thank You Program",
    galleryProfile: "images/multimedia/thankyouprogram/pic8.jpg",
    src: [
      {
        path: "images/multimedia/thankyouprogram/pic1.jpg",
        imgId: "105A",
      },
      {
        path: "images/multimedia/thankyouprogram/pic4.jpg",
        imgId: "105B",
      },
      {
        path: "images/multimedia/thankyouprogram/pic7.jpg",
        imgId: "105C",
      },
      {
        path: "images/multimedia/thankyouprogram/pic8.jpg",
        imgId: "105D",
      },
      {
        path: "images/multimedia/thankyouprogram/pic3.jpg",
        imgId: "105E",
      },
      {
        path: "images/multimedia/thankyouprogram/pic11.jpg",
        imgId: "105F",
      },
      {
        path: "images/multimedia/thankyouprogram/pic16.jpg",
        imgId: "105G",
      },
      {
        path: "images/multimedia/thankyouprogram/pic15.jpg",
        imgId: "105H",
      },
    ],
    carouselElementId: "multimediagallery",
    content: "Thank You Program",
  },
  {
    id: 6,
    galleryId: "G6",
    galleryName: "Greeting Card and Speech",
    galleryProfile: "images/multimedia/greetingcardandspeech/pic2.jpeg",
    src: [
      {
        path: "images/multimedia/greetingcardandspeech/pic1.jpeg",
        imgId: "106A",
      },
      {
        path: "images/multimedia/greetingcardandspeech/pic2.jpeg",
        imgId: "106B",
      },
      {
        path: "images/multimedia/greetingcardandspeech/pic4.jpeg",
        imgId: "106C",
      },
      {
        path: "images/multimedia/greetingcardandspeech/pic5.jpeg",
        imgId: "106D",
      },
      {
        path: "images/multimedia/greetingcardandspeech/pic7.jpeg",
        imgId: "106F",
      },
      {
        path: "images/multimedia/greetingcardandspeech/pic9.jpeg",
        imgId: "106G",
      },
      {
        path: "images/multimedia/greetingcardandspeech/pic10.jpeg",
        imgId: "106H",
      },
      {
        path: "images/multimedia/greetingcardandspeech/pic11.jpeg",
        imgId: "106I",
      },
    ],
    carouselElementId: "multimediagallery",
    content: "Greeting Card and Speech",
  },
  {
    id: 7,
    galleryId: "G7",
    galleryName: "Gurupurnime",
    galleryProfile: "images/multimedia/gurupurnime/pic1.jpg",
    src: [
      { path: "images/multimedia/gurupurnime/pic1.jpg", imgId: "107A" },
      { path: "images/multimedia/gurupurnime/pic2.jpg", imgId: "107B" },
      { path: "images/multimedia/gurupurnime/pic3.jpg", imgId: "107C" },
      { path: "images/multimedia/gurupurnime/pic4.jpg", imgId: "107D" },
      { path: "images/multimedia/gurupurnime/pic5.jpg", imgId: "107E" },
      { path: "images/multimedia/gurupurnime/pic6.jpg", imgId: "107F" },
    ],
    carouselElementId: "multimediagallery",
    content: "Gurupurnime",
  },
  {
    id: 8,
    galleryId: "G8",
    galleryName: "National Yoga Day",
    galleryProfile: "images/multimedia/nationalyogaday/pic3.jpeg",
    src: [
      { path: "images/multimedia/nationalyogaday/pic1.jpeg", imgId: "108A" },
      { path: "images/multimedia/nationalyogaday/pic2.jpeg", imgId: "108B" },
      { path: "images/multimedia/nationalyogaday/pic3.jpeg", imgId: "108C" },
      { path: "images/multimedia/nationalyogaday/pic4.jpeg", imgId: "108D" },
      { path: "images/multimedia/nationalyogaday/pic5.jpeg", imgId: "108E" },
      { path: "images/multimedia/nationalyogaday/pic6.jpeg", imgId: "108F" },
      { path: "images/multimedia/nationalyogaday/pic7.jpeg", imgId: "108G" },
      { path: "images/multimedia/nationalyogaday/pic8.jpeg", imgId: "108H" },
    ],
    carouselElementId: "multimediagallery",
    content: "National Yoga Day",
  },
  {
    id: 9,
    galleryId: "G9",
    galleryName: "Rhymes Vachana Recitation",
    galleryProfile: "images/multimedia/rhymesvachanarecitation/pic4.jpeg",
    src: [
      {
        path: "images/multimedia/rhymesvachanarecitation/pic1.jpeg",
        imgId: "109A",
      },
      {
        path: "images/multimedia/rhymesvachanarecitation/pic2.jpeg",
        imgId: "109B",
      },
      {
        path: "images/multimedia/rhymesvachanarecitation/pic3.jpeg",
        imgId: "109C",
      },
      {
        path: "images/multimedia/rhymesvachanarecitation/pic4.jpeg",
        imgId: "109D",
      },
      {
        path: "images/multimedia/rhymesvachanarecitation/pic5.jpeg",
        imgId: "109E",
      },
      {
        path: "images/multimedia/rhymesvachanarecitation/pic6.jpeg",
        imgId: "109F",
      },
    ],
    carouselElementId: "multimediagallery",
    content: "Rhymes Vachana Recitation",
  },
];
const arr = obj.map((e) => {
  return {
    galleryId: e.galleryId,
    galleryName: e.galleryName,
    galleryProfile: e.galleryProfile,
  };
});

const initialState = {
  id: 0,
  src: [],
  carouselElementId: "",
  content: "",
};
const reducer = (state, action) => {
  switch (action.type) {
    case "Infrastructure": {
      return obj[0];
    }
    case "Class Activites": {
      return obj[1];
    }
    case "Inter School Flok-Dance Competation": {
      return obj[2];
    }
    case "English Hub": {
      return obj[3];
    }
    case "Thank You Program": {
      return obj[4];
    }
    case "Greeting Card and Speech": {
      return obj[5];
    }
    case "Gurupurnime": {
      return obj[6];
    }
    case "National Yoga Day":{
      return obj[7];
    }
    case "Rhymes Vachana Recitation":{
      return obj[8];
    }
    default: {
      return state;
    }
  }
};

const MultiMediaGallery = (props) => {
  const [media, dispatcher] = useReducer(reducer, initialState);
  const gallery = (galleryName) => {
    dispatcher({ type: galleryName });
  };

  return (
    <>
      <div className="container">
        <h1 className="sepl-primary-dark d-flex justify-content-center">
          Multi Media
        </h1>
        <div className="row">
          {arr.map((element) => {
            return (
              <div key={element.galleryId} className="col-md-4 mb-4">
                <div className="multimediagallery">
                  <button
                    type="button"
                    className="btn p-0 border-2 multimediagallery-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                    onClick={() => {
                      gallery(element.galleryName);
                    }}
                  >
                    <img
                      src={element.galleryProfile}
                      alt=""
                      className="multimediagallery-profile-img"
                    />
                  </button>
                  <h5 style={{ textAlign: "center" }} className="pb-3">
                    {element.galleryName}
                  </h5>
                </div>
              </div>
            );
          })}
          <div className="modal" id="myModal">
            <div className="modal-dialog center-modal w-90">
              <div className="modal-content">
                {media !== "" && <MultiMediaCarousel data={media} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Heromodule /> */}
    </>
  );
};
export default MultiMediaGallery;
