import "./Cookie.css";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Cookie = () => {
  // Replace the className with "className"
  useEffect(() => {
    (() => {
      const getCookie = (name) => {
        const value = " " + document.cookie;
        console.log("value", `==${value}==`);
        const parts = value.split(" " + name + "=");
        return parts.length < 2 ? undefined : parts.pop().split(";").shift();
      };

      const setCookie = function (
        name,
        value,
        expiryDays,
        domain,
        path,
        secure
      ) {
        const exdate = new Date();
        exdate.setHours(
          exdate.getHours() +
            (typeof expiryDays !== "number" ? 365 : expiryDays) * 24
        );
        document.cookie =
          name +
          "=" +
          value +
          ";expires=" +
          exdate.toUTCString() +
          ";path=" +
          (path || "/") +
          (domain ? ";domain=" + domain : "") +
          (secure ? ";secure" : "");
      };

      const $cookiesBanner = document.querySelector(".cookies-eu-banner");
      const $cookiesBannerButton = $cookiesBanner.querySelector("button");
      const cookieName = "cookiesBanner";
      const hasCookie = getCookie(cookieName);

      if (!hasCookie) {
        $cookiesBanner.classList.remove("hidden");
      }

      $cookiesBannerButton.addEventListener("click", () => {
        setCookie(cookieName, "closed");
        $cookiesBanner.remove();
      });
    })();
  });

  return (
    <>
      <div className="cookies-eu-banner hidden">
        Do you like cookies? 🍪 We use cookies to ensure you get the best
        experience on our website.{" "}
        <NavLink to={"/privacypolicy"}>Privacy policy</NavLink>
        <button>I Agree</button>
      </div>
    </>
  );
};

export default Cookie;
