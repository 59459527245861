import { NavLink } from "react-router-dom";
import "./Footer.css";

const ActFooter = () => {
  return (
    <>
      <div className="container-fluid pt-2 mb-5 mb-md-0">
        <div className="row footer">
          <h1 className="show-for-mobile-view" style={{ textAlign: "center" }}>
            ACT Amrita International school
          </h1>
          <div className="col-md-2 col-lg-2">
            <img src="images/logo/actais.png" className="footer-logo" alt="" />
          </div>
          <div className="col-6 col-md-2 col-lg-2 pt-0 pt-md-5">
            <h5 className="coloring">Location</h5>
            <p>
              <span className="hide-for-mobile-view">
                ACT Amrita International school,
              </span>
              Dr H M Shirur Campus Sector no-110,Road no 17 Navanagar,
              Bagalkot-587104, Karnataka, India
            </p>
          </div>
          <div className="col-6 col-md-2 pt-0 pt-md-5 px-2">
            <h5 className="coloring">Get in touch</h5>
            <ul className="get-in-touch">
              <li className="get-in-touch-link">
                <NavLink to={"/contactus"}> Contact us</NavLink>
              </li>
              <li className="get-in-touch-link">
                <NavLink to={"/programs"}>Program</NavLink>
              </li>
              <li className="get-in-touch-link">
                <NavLink to={"/faqs"}>FAQs</NavLink>
              </li>
              <li className="get-in-touch-link">
                <NavLink to={"/privacypolicy"}>Privacy policy</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-4 col-md-2 pt-0 pt-md-5 px-md-0 m-0">
            <h5 className="coloring">Follow Us</h5>
            <ul className="follow-us">
              <li className="social-media">
                <a
                  href="https://www.facebook.com/profile.php?id=100091624549336"
                  className="bi bi-facebook"
                ></a>
              </li>
              <li className="social-media">
                <a
                  href="https://www.instagram.com/act_amrita_school_bagalkot/"
                  className="bi bi-instagram"
                ></a>
              </li>
              <li className="social-media">
                <a
                  href="https://www.linkedin.com/in/ACT Amrita-international-school-bagalkot-5b5854272/"
                  className="bi bi-linkedin"
                ></a>
              </li>
            </ul>
          </div>
          <div className="col-4 col-md-2 pt-0 pt-md-5 m-0">
            <h5 className="coloring">Contact Us</h5>
            <p style={{ margin: 0 }}>Phone number: 70262 22296</p>
            <p style={{ fontSize: "small", margin: 0 }}>
              <strong>email: </strong>principalactamritaschool@gmail.com
            </p>
          </div>
          <div className="col-4 col-md-2 d-flex justify-content-end">
            <img
              src="images/logo/act.png"
              className="footer-logo1"
              alt=""
              srcSet=""
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ActFooter;
