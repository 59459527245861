import React from "react";
import Seo from "../global/Seo";
import HeromoduleVideo from "../modules/hero/HeromoduleVideo";
import Textmodule from "../modules/textmodules/Textmodule";
import Dualcoloums from "../modules/Dualcoloums";

import Subject from "../modules/Subject";
import Extracurricularprograms from "../modules/Extracurricularprograms";
const objsome = {
  title: "Prep Hour at ACT Amrita International School",
  lead: " Empowering Minds. Enriching Lives. That's the Prep Hour Promise.",
  imagesrc: "/images/prephour.jpg",
  content:
    "Transforming homework into a shared, enriching experience. From 3-4 pm daily, students gather in a supportive circle to complete assignments, guided by teachers. Enjoy a stress-free evening with homework largely done. Join us to foster collaboration,enhance learning, and create memorable moments.",

  buttonreqd: false,
  buttonsrc: "",
  rightImg: "rev",
};
const objskill = {
  title: "Skill Based Development",
  lead: "",
  content:
    " Every human being is inherently designed for success; if anyone experiences failure, it becomes a tragic missed opportunity to realize their potential.  Our educational institute is dedicated to fostering skill-based development in children, providing a dynamic and enriching environment where young minds can flourish.Through innovative teaching methodologies and personalized guidance, we empower students to cultivate essential skills that extend beyond traditional academics, preparing them for a futureof success and adaptability.",
  imagesrc: "/images/skillbased.jpg",
  buttonreqd: false,
  buttonsrc: "",
  rightImg: "",
};

const objSeo = {
  title: "ACT Amrita International School: Programs- Empowering Excellence",
  keywords:
    "Excellence in education, program, dynamic learning, comprehensive curriculum, critical thinking, creativity, holistic development",
  pathSlug: "programs",
  description:
    "Step into a world of educational excellence with our Program. Here, education transforms into a dynamic and comprehensive journey, equipping students to excel academically, personally, and socially. As a respected institution, we're dedicated to delivering a top-tier curriculum that nurtures critical thinking, sparks creativity, and facilitates holistic growth. Join us in shaping a brighter future through education that goes beyond the ordinary.",
};

const Programs = () => {
  return (
    <>
      <Seo data={objSeo} />

      <HeromoduleVideo></HeromoduleVideo>

      <Textmodule></Textmodule>
      <Dualcoloums data={objskill} />

      <Subject></Subject>
      <Extracurricularprograms />
      <Dualcoloums data={objsome} />
    </>
  );
};

export default Programs;
