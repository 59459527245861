import React from "react";
import Seo from "../global/Seo";
// import Facultystaffcomp from "../modules/Facultystaff";
import Commingsoon from "../modules/Commingsoon";
const objSeo = {
  title: "ACT Amrita International School: Faculty - Meet Our Expert Educators",
  keywords:
    "Faculty, educators, program, teaching staff, expertise, education,ACT Amrita international school, Bagalkot, Dr Gururaj Karajagi, Jyotikiran Das, Ravi Hulyal,Bheemsen Sedamkar",
  pathSlug: "faculty-staff",
  description:
    "Get acquainted with the heart of our educational institution – our distinguished faculty members who drive excellence in our Program. With a team of dedicated educators, we ensure a transformative learning experience for every student. ",
};

const FacultyStaff = () => {
  return (
    <>
      <Seo data={objSeo} />

      {/* <Facultystaffcomp></Facultystaffcomp> */}
      <Commingsoon></Commingsoon>
    </>
  );
};

export default FacultyStaff;
