import React from "react";
import Seo from "../global/Seo";
import Fullwidth from "../modules/Fullwidth";
import Dualcoloums from "../modules/Dualcoloums";
import FiveTiles from "../modules/fivetilesmouseover/FiveTiles";
import Heromoduletwocol from "../modules/Heromoduletwocol";
import Promo from "../modules/promo/promo";
const objprograms = {
  title: "Program Overview",
  lead: "Unlocking Excellence in Education",
  imagesrc: "/images/cbse.jpg",
  content:
    " We are excited to announce our Program, where learning takes on a dynamic and comprehensive form, empowering students to thrive academically, personally, and socially. As an esteemed educational institute, we are committed to providing a top-notch curriculum that fosters critical thinking, creativity, and holistic development.",
  buttonreqd: true,
  buttonsrc: "/programs",
  rightImg: "rev",
};

const objwhylearnaais = {
  title: "Why learn at ACT Amrita International School",
  lead: "",
  imagesrc: "/images/dualColumn/whylearn.jpg",
  content:
    "A consious mix of Indian aesthetics and modern technology.Integration of values in every lesson.Creative Teaching Tecniques employed by teachers. It is the only institution where teachers are trained on NEP integration and also on newer methods,on a regular basis.Students are encouraged to take up community projects.",
  buttonreqd: true,
  buttonsrc: "/why-aais",
  rightImg: "",
};

const objSeo = {
  title:
    "ACT Amrita International School: A Pioneering School in Bagalkot, Karnataka",
  keywords:
    "Indian aesthetics, modern technology, ACT Amrita International School, Bagalkot Karnataka, creative teaching techniques, NEP-integrated training, individualized attention",
  pathSlug: "",
  description:
    "Discover a perfect blend of Indian aesthetics and modern technology at ACT Amrita International School in Bagalkot, Karnataka. Our institution stands out with creative teaching techniques, NEP-integrated training, and a commitment to individualized attention.",
};

const Home = () => {
  // Replace the class with "className"
  return (
    <>
      <Seo data={objSeo} />
      <Heromoduletwocol />
      <Dualcoloums data={objwhylearnaais}></Dualcoloums>
      <FiveTiles></FiveTiles>
      <Fullwidth></Fullwidth>
      <Dualcoloums data={objprograms} />
      <Promo />
    </>
  );
};
export default Home;
