import React from "react";
import ColumnModule from "../modules/ColumnModule";

const AboutUs = () => {
  return (
    <div>
      <ColumnModule />
    </div>
  );
};
export default AboutUs;
