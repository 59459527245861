import "./Heromoduleoneimage.css";
const Heromoduleoneimage = (props) => {
  return (
    <>
      <div className="container-fluid heromodulecontainer">
        <img src="/images/complab3.jpg" className="hero-one-img" />
        <div className="heromodule-caption w-50">
          <h2 className="sepl-primary-dark ">
            Why ACT Amrita International School
          </h2>

          {/* <p className="d-none d-md-block  sepl-primary-dark">
            Our goal is to create enlightened and empowered young men and women
            for whom success comes naturally.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default Heromoduleoneimage;
