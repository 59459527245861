import React from "react";
import Seo from "../global/Seo";
import Dualcolinfo from "../modules/Dualcolinfo";
import Sunsection from "../modules/Sunsetion";
import Textmoduleactvpm from "../modules/textmodules/Textmoduleactvpm";
import HeroModuleRegistration from "../modules/HeroModuleRegistration";

const objinfo = {
  title: "ACT's MISSION",
  lead: "ACT's JOURNEY SO FAR",
  imagesrc: "images/dualcolinfo/info2.png",
  content:
    "Academy for Creative Teaching (ACT) is a unique instruction for training and education consultancy in india.It has also undertaken quality audit of schools andorganisations.It has organised several leadership training programmes forschool leaders of CBSE, ICSE schools. ACT has built and envisioned89 institutions in India and abroad.",
  buttonreqd: false,
  buttonsrc: "",
};

const objSeo = {
  title: "ACT Amrita International School: Bridging Tradition and Modernity",
  keywords:
    "ACT Amrita International School, educational empowerment, VPM Trust, ACT, Bagalkot, tradition and modernity, state-of-the-art facilities, nurturing brilliance",
  pathSlug: "act-vpm-synergy",
  description:
    "VPM Trust and ACT have partnered to create the ACT Amrita International School in Bagalkot. We harmonize tradition with modernity, offering state-of-the-art facilities that exemplify our commitment to nurturing brilliance. Join us in this unique educational journey.",
};

const ActvpmSynergy = () => {
  return (
    <>
      <Seo data={objSeo} />

      <div class="actvpmpage">
        <HeroModuleRegistration imagesrc="/images/actvpm.jpg"></HeroModuleRegistration>
      </div>
      <Textmoduleactvpm></Textmoduleactvpm>

      <Sunsection></Sunsection>
      <Dualcolinfo data={objinfo}></Dualcolinfo>
    </>
  );
};

export default ActvpmSynergy;
