import "./App.css";
import Navigation from "./components/global/Navigation";
import Home from "./components/pages/Home";
import { Routes, Route } from "react-router-dom";
import FacultyStaff from "./components/pages/FacultyStaff";
import MultiMedia from "./components/pages/MultiMedia";
import Cookie from "./components/global/Cookie";
import InMedia from "./components/pages/InMedia";
import ActvpmSynergy from "./components/pages/ActvpmSynergy";
import Whyaais from "./components/pages/Whyaais";
import Contactus from "./components/pages/Contactus";
import Whatsapp from "./components/modules/Whatsapp";
import QuickLinks from "./components/global/QuickLinks";
import Faqs from "./components/pages/Faqs";
import Registration from "./components/pages/Registration";
import Programs from "./components/pages/Programs";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import ActFooter from "./components/modules/footer/ActFooter";
import Principal from "./components/pages/PrincipalLetter";
import AboutUs from "./components/pages/AboutUs";
import TableFiles from "./components/pages/TableFiles";

const App = (props) => {
  return (
    <>
      <QuickLinks></QuickLinks>
      <Navigation></Navigation>

      <Routes>
        {<Route path="/" element={<Home></Home>} />}
        {<Route path="/why-aais" element={<Whyaais></Whyaais>} />}
        {
          <Route
            path="/leadershipteam"
            element={<FacultyStaff></FacultyStaff>}
          />
        }
        {<Route path="/multimedia" element={<MultiMedia></MultiMedia>} />}
        <Route path="/programs" element={<Programs></Programs>} />
        {<Route path="/contactus" element={<Contactus></Contactus>} />}
        {<Route path="/inmedia" element={<InMedia></InMedia>} />}
        <Route path="/faqs" element={<Faqs></Faqs>} />
        {<Route path="/registration" element={<Registration></Registration>} />}
        {
          <Route
            path="/act-vpm-synergy"
            element={<ActvpmSynergy></ActvpmSynergy>}
          />
        }
        {
          <Route
            path="/privacypolicy"
            element={<PrivacyPolicy></PrivacyPolicy>}
          />
        }
        {
          <Route
            path="/welcome-message-from-our-principal"
            element={<Principal></Principal>}
          />
        }
        {<Route path="/aboutus" element={<AboutUs></AboutUs>} />}
        {
          <Route
            path="/mandatory-disclosure"
            element={<TableFiles></TableFiles>}
          />
        }
        <Route path="*" element={"404 page not found"} />
        {/* Create the PageNotFound component to render error message in UI */}
      </Routes>
      <Whatsapp></Whatsapp>
      <Cookie></Cookie>
      <ActFooter />
    </>
  );
};
export default App;
