import "./Videodualcolumn.css";
const Fstdualcolumn = (props) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 pt-4 pb-4">
            <iframe
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100091624549336%2Fvideos%2F686553286569507%2F&show_text=false&width=560&t=0"
              className="videodualcolumn"
              scrolling="no"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen="true"
            ></iframe>
          </div>
          <div className="col-md-6 pt-3">
            <div className="seplsquare d-none d-md-block"></div>
            <h1>Dr. Gururaj Karajagi - CHAIRMAN- ACT</h1>

            <p>
              Dr. Gururaj Karajagi is a doctorate in Chemistry and has published
              over 22 research papers in International journals.He is a life
              Fellow of the Electrochemical Society of India.He has been a
              teacher for over four decades without a moment's regret.
            </p>
            <p>
              He is considered as an outstanding motivational speaker and his
              You Tube channel - “Knowledge is spherical” has nearly three crore
              views.Through the Academy for Creative Teaching, of which he is
              the Chairman, Dr. Karajagi is instrumental in building over 105
              schools of very high quality all over the world.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fstdualcolumn;
