import React from "react";
import "./Textmodule.css";
const ContentLayout = () => {
  return (
    <div className="content-container textmodule">
      <div className="row">
        <div className="col-12 text-justify">
          <h1>
            <strong>Unison Excellence: The Synergy of ACT and VPM Trust</strong>
          </h1>
          <p>
            Established in 1920, the VPM Trust has been an unwavering beacon of
            educational empowerment. Joining hands with ACT, a pioneering force
            in educational consultancy in India, these two trailblazing
            organizations have converged their expertise to give rise to a
            remarkable institution in Bagalkot - The ACT Amrita International
            School. At ACT Amrita International School, we are more than just an
            educational establishment; we are a symphony of tradition and
            modernity, heritage and progress. Our partnership has bestowed upon
            this institution state-of-the-art facilities that stand as a
            testament to our commitment to nurturing brilliance.
          </p>
        </div>
        <div className="col-md-6 text-justify">
          <h5>
            <strong>Our Distinctive Blend</strong>
          </h5>
          <p>
            At the heart of our institution lies a unique synergy. Our heritage,
            embedded in the roots of VPM Trust, marries seamlessly with the
            innovative spirit of ACT, resulting in a curriculum that
            harmoniously blends values, creativity, and academic rigor. We are
            proud to declare that we stand fully aligned with the National
            Education Policy, embracing its forward-thinking approach to
            holistic education.
          </p>
        </div>
        <div className="col-md-6 text-justify">
          <h5>
            <strong>Empowering Educators, Inspiring Excellence</strong>
          </h5>
          <p>
            One of our hallmarks is the dedicated training of our educators by
            the esteemed faculties of ACT. Our teachers are not merely
            instructors; they are torchbearers of knowledge and mentors of
            character. This commitment to continuous growth ensures that your
            child is nurtured by individuals who are well-versed in the latest
            pedagogical techniques and philosophies.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 text-justify">
          <h5>
            <strong>A Holistic sanctum</strong>
          </h5>
          <p>
            ACT Amrita International School is more than a place of learning; it
            is a haven for the comprehensive development of your child. We
            recognize that education extends far beyond textbooks. Our
            meticulously designed approach caters to the physical, emotional,
            intellectual, and spiritual facets of your child's growth. We foster
            an environment that encourages exploration, critical thinking, and
            the nurturing of a strong moral compass.
          </p>
        </div>
        <div className="col-md-6 text-justify">
          <h5>
            <strong>Join Us in Crafting the Future</strong>
          </h5>
          <p>
            As we embark on this remarkable journey of education and
            enlightenment, we extend a warm invitation to parents and guardians
            who share our vision. Together, let us nurture a generation that not
            only excels academically but also contributes meaningfully to
            society. Discover excellence redefined at ACT Amrita International
            School - where legacy meets innovation, and dreams find their wings.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContentLayout;
