const Playground = (props) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 pt-4">
            <h1>Boundless Play, Inspiring Learning.(Planned)</h1>

            <p>
              Where learning takes flight, and dreams roam free, our school's
              8-acre paradise awaits, with boundless space for play and
              discovery!. We are creating a futuristic 400m track field with an
              inside track that will house a hockey field and a cricket ground.
              Outside, we will have volleyball and kabaddi grounds, all within a
              fully gated area.
            </p>
          </div>
          <div className="col-md-6 pt-4">
            <img
              src="/images/track.jpg"
              className="img-fluid"
              alt="proposed track"
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Playground;
