import "./Extracurricularprograms.css";
const Extracurricularprograms = () => {
  return (
    <>
      <div className="container text-center">
        <div className="multitilesheader">
          <h1 className="sepl-primary-dark mt-3">EXTRACURRICULAR PROGRAMS</h1>
        </div>
        <div className="row align-items-start mb-5">
          <div className="col-4 col-md-4">
            <img src="/images/dance.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 ">Dance</h6>
          </div>
          <div className="col-4 col-md-4">
            <img src="/images/exercise.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 ">Physical Education</h6>
          </div>
          <div className="col-4 col-md-4">
            <img src="/images/painting.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3  ">Art</h6>
          </div>
        </div>
        <div className="row align-items-start mb-2 mb-md-0">
          <div className="col-4 col-md-4">
            <img src="/images/speechh.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 growth-heading">
              Public Speaking
            </h6>
          </div>
          <div className="col-4 col-md-4">
            <img src="/images/tone.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 growth-heading">Music</h6>
          </div>
          <div className="col-4 col-md-4">
            <img src="/images/tennis.png" width="80" height="60" />
            <h6 className="sepl-secondary-dark mt-3 growth-heading">Sports</h6>
          </div>
        </div>
      </div>
    </>
  );
};
export default Extracurricularprograms;
