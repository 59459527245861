import "./Heromoduleoneimage.css";
const Heromodulefaqs = (props) => {
  return (
    <>
      <div className="container-fluid heromodulecontainer">
        <img src="/images/faqs.jpg" className="hero-one-img" />
        <div className="heromodule-caption w-50"></div>
      </div>
    </>
  );
};

export default Heromodulefaqs;
