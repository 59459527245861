import { Helmet } from "react-helmet";

const Seo = (props) => {
  const url = `https://actais.in/${props.data.pathSlug}`;
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={props.data.title}
      meta={[
        {
          name: "description",
          content: props.data.description,
        },
        {
          name: "keywords",
          content: props.data.keywords,
        },
        {
          name: "og:title",
          content: props.data.title,
        },
        {
          name: "og:description",
          content: props.data.description,
        },
        {
          name: "og:type",
          content: "article",
        },
        {
          name: "og:url",
          content: url,
        },
      ]}
      link={[
        {
          rel: "canonical",
          key: url,
          href: url,
        },
      ]}
    />
  );
};
export default Seo;
