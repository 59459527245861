import "./Sunsetion.css";
const Sunsetion = (props) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 subsetion1">
            <h1 className="Sunsetiontitle">ACT-Academy for Creative</h1>
            <h1 className="Sunsetiontitle"> Teaching</h1>
            <img
              src="/images/logo/act.png"
              className="sunsetionlogo actlogo"
              alt="ACT logo"
            ></img>
            <figcaption className="block-footer1">
              A Unique organisation for educational consultancy in India
            </figcaption>

            <div className="sub1 p-1 pt-4">
              <p>
                ACT has on its board a rich source of passionate
                educationists,academicians and technologists who have
                envisioned,built and managed quality institutions at all levels.
                ACT is guided by a high power Governing Council consisting of
                outstanding people of India.
              </p>
              <p>
                ACT is a pioneering body that has consistently been instrumental
                in providing pre-service and in-service training to teachers at
                all levels and also in providing insitution-building support
                systems.
              </p>
            </div>
          </div>
          <div className="col subsetion2">
            <h1 className="Sunsetiontitle1">Vidya Prasarak Mandal </h1>
            <h1 className="Sunsetiontitle1">Trust </h1>
            <img
              src="/images/logo/vpmlogo.png"
              className="sunsetionlogo"
              alt="VPM logo"
            ></img>

            <figcaption className="block-footer2">
              The Trust started in 1920
            </figcaption>

            <div className=" sub  p-1 pt-4">
              <p>
                Vidya Prasarak Mandal, a distinguished institution founded in
                1920 by visionary intellectuals with the noble purpose of
                imparting knowledge to the masses. Our journey began with the
                establishment of the New English School, which took root in the
                home of Ramannanayaka Savanur in Venkatapet.
              </p>
              <p>
                Today, our legacy continues, and while we have expanded to
                multiple locations, we are affectionately known as Sakri High
                School. With over a century of experience, our organization has
                grown steadily.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sunsetion;
