import { useEffect } from "react";

const Modal = (props) => {
  useEffect(() => {
    var exampleModal = document.getElementById("exampleModal");
    exampleModal.addEventListener("show.bs.modal", function (event) {
      var button = event.relatedTarget;
      var imgsrc = button.getAttribute("data-bs-imgsrc");
      var imgele = exampleModal.querySelector(".imgclass");
      imgele.src = imgsrc;
    });
  });

  return (
    <div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Close</span>
              </button>
            </div> */}
            <div className="modal-body">
              <img src="" className="imgclass" style={{ width: "100%" }}></img>
            </div>
          </div>
        </div>
      </div>
      ;
    </div>
  );
};
export default Modal;
