import "./Dualcoloums.css";
const Dualcoloums = (props) => {
  console.log(props.data.rightImg);
  return (
    <>
      <div className="container py-3">
        <div className={`row ${props.data.rightImg}`}>
          <div className="col-md-6 pt-3 ">
            <img
              src={props.data.imagesrc}
              className="seplimg img-fluid d-flex justify-content-center"
            ></img>
          </div>
          <div className="col-md-6 pt-3">
            <div className="seplsquare d-none d-md-block"></div>
            <h1>{props.data.title}</h1>

            <p>{props.data.content}</p>
            <div className="sesplbutton">
              {props.data.buttonreqd && (
                <a href={props.data.buttonsrc}>
                  <button type="button" className="btn btn-outline-primary">
                    Read more
                  </button>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dualcoloums;
