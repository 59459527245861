import React from "react";
import "./FiveTiles.css";
const FiveTiles = () => {
  return (
    <>
      <div className="five-tile-main-container py-4 px-2 px-md-0 ">
        <h1 style={{ textAlign: "center" }}>Our Commitments</h1>

        <div className="row">
          <div className="col-6 col-md-4 border p-0 fivetile-title-without-img">
            <div className="five-tile-overlay-container">
              <div className="fivetile-title">
                <strong class=" bg-dark">Quality Education</strong>
              </div>
              <div className="overlay px-1 pt-1 ">
                <strong className="bg-dark" style={{ display: "block" }}>
                  Quality Education
                </strong>
                ACT Amrita International School offers rich, holistic,
                international and academically rigorous education that draws on
                local and global perspectives with international certification.
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3 offset-0 border p-0">
            <div className="five-tile-overlay-container">
              <img src="images/fivetiles/loveforlearning.jpg" alt="" />
              <div className="fivetile-title-with-img">
                <strong class=" bg-dark">
                  <i className="bi bi-stack"> </i>
                  Love for learning
                </strong>
              </div>
              <div className="overlay-2 px-1 pt-1 ">
                <strong className="bg-dark" style={{ display: "block" }}>
                  Love for learning
                </strong>
                ACT Amrita International School empowers students continuous
                learning. with the skills of critical thinking, creative
                thinking and love for continous learning.
              </div>
            </div>
          </div>
          <div className="col-6 col-md-5 offset-0 border p-0 fivetile-title-without-img">
            <div className="five-tile-overlay-container">
              <div className="fivetile-title">
                <strong className="bg-dark">Foundation for Values</strong>
              </div>
              <div className="overlay px-2 pt-1 ">
                <strong className="bg-dark" style={{ display: "block" }}>
                  Foundation for Values
                </strong>
                ACT Amrita International School instills in children the
                timeless values that are integral to living an interdependent,
                equitable and fulfilled life.
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3 offset-0 border p-0">
            <div className="five-tile-overlay-container">
              <img src="images/fivetiles/studentempowerment.jpg" alt="" />
              <div className="fivetile-title-with-img">
                <strong className=" bg-dark">Student empowerment</strong>
              </div>
              <div className="overlay-2 px-1 pt-1">
                <strong className="bg-dark" style={{ display: "block" }}>
                  Student empowerment
                </strong>
                ACT Amrita International School fosters student ownership of
                their learning, developing confidence, self-esteem and the
                skills to meet the challenges that confront them today and in
                the future.
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 offset-0 border p-0">
            <div className="five-tile-overlay-container">
              <img src="images/fivetiles/globalcitizen.jpg" alt="" />
              <div className="fivetile-title-with-img">
                <strong class=" bg-dark">
                  <i className="bi bi-universal-access-circle"> </i>
                  Global citizens
                </strong>
              </div>
              <div className="overlay px-4 pt-1">
                <strong className="bg-dark" style={{ display: "block" }}>
                  Global citizens
                </strong>
                ACT Amrita International School teaches children to be global
                citizens, capable of adapting to changing situations and
                challenges.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiveTiles;
