import "./Commingsoon.css";
import Form from "./Contactforms";
const Commingsoon = () => {
  // Replace the class with "className"
  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row ">
          <div className="col-md-4 offset-1 py-4">
            <h1 className="text-9 text-white bg-danger d-inline-block fw-700 rounded px-md-3 py-2 mb-4">
              Coming Soon!
            </h1>
            <h2 className="text-15 fw-600 mb-4">
              Our new website is on its way.
            </h2>
          </div>
          <div className="col-9 offset-2 col-md-4 offset-md-2 border my-md-2 bg-light">
            <div className="text-center px-md-0 py-md-0">
              <h2>Contact Us</h2>
            </div>
            <Form />
          </div>
        </div>
      </div>
    </>
  );
};

export default Commingsoon;
