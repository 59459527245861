import "./Fullwidth.css";
const Fullwidth = (props) => {
  return (
    <>
      <figure className="text-left">
        <blockquote className="blockquote-Fullwidth pe-2 ">
          <p>
            "The joy of giving is <br />
            the purpose of life <br />
            and that is the <br />
            success of life"
          </p>
        </blockquote>
        <figcaption className="blockquote-footer pe-2">
          -Dr Gururaj Karajagi
        </figcaption>
      </figure>
      <img src="/images/gks3.png" className="fullwidthimage" alt=" " />
      <div className="container fullwidth">
        <div className="row mx-md-5">
          <div className="col fullwidthcol">
            <div className="views-row">
              <h2>VISION</h2>
              Our vision is to provide a happy, caring and stimulating
              environment where children will recognize and achieve their
              fullest potential, so taht they can make their best contribution
              to society.
            </div>
          </div>
          <div className="col fullwidthcol">
            <div className="views-row">
              <h2>MISSION </h2>
              The School recognizes that each child an individual; that all
              children are creative; that all children need to succeed.
              Therefore, the school respects the individual needs of children;
              fosters a craing and creative environment; and emphasises the
              emotinal, physical, intellectual and spiritual development of each
              child.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fullwidth;
