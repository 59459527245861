import React from "react";
import PrincipalMessage from "../modules/PrincipalMessage";

const PrincipalLetter = () => {
  return (
    <div>
      <PrincipalMessage />
    </div>
  );
};

export default PrincipalLetter;
