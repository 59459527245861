import "./Navigation.css";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

const Navigation = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/aboutus");
  };

  // Replace the class with "className"
  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top  seplnavbar ">
        <div className="container-fluid ">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <img
            alt="actlogo.png"
            src="images/logo/actais.png"
            className="logo"
          />
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item pe-5 pl-91">
                <NavLink
                  to={"/"}
                  className={"nav-link "}
                  style={{
                    color: "#ffffff",
                    // paddingRight: "20px",
                    // paddingLeft: "91px",
                  }}
                  id="space"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown ">
                <a
                  className="nav-link dropdown-toggle"
                  href="/aboutus"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: "white", paddingRight: "65px" }}
                  onClick={handleClick}
                >
                  About Us
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a class="dropdown-item" href="/why-aais">
                      Why AAIS
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/act-vpm-synergy">
                      ACT-VPM Synergy
                    </a>
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/welcome-message-from-our-principal"
                    >
                      Welcome message from our principal
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/leadershipteam">
                      Leadership Team
                    </a>
                  </li>
                </ul>
              </li>

              {/* <li className="nav-item pe-5">
                <NavLink
                  to={"/welcome-message-from-our-principal"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                  }}
                >
                  PrincipalLetter
                </NavLink>
              </li> */}
              {/* <li className="nav-item pe-5">
                <NavLink
                  to={"/why-aais"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                  }}
                >
                  Why AAIS
                </NavLink>
              </li> */}
              {/* <li className="nav-item pe-5">
                <NavLink
                  to={"/act-vpm-synergy"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                  }}
                >
                  ACT-VPM Synergy
                </NavLink>
              </li> */}
              {/* <li className="nav-item pe-5">
                <NavLink
                  to={"/aboutus"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                  }}
                >
                  Aboutus
                </NavLink>
              </li> */}

              <li className="nav-item pe-5">
                <NavLink
                  to={"/programs"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                    // paddingRight: "20px",
                  }}
                >
                  Programs
                </NavLink>
              </li>
              <li className="nav-item pe-5">
                <NavLink
                  to={"/registration"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                    paddingRight: "20px",
                  }}
                >
                  Registration
                </NavLink>
              </li>
              {/* <li className="nav-item pe-5">
                <NavLink
                  to={"/faculty-staff"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                  }}
                >
                  LeaderShip Team
                </NavLink>
              </li> */}
              <li className="nav-item pe-5">
                <NavLink
                  to={"/multimedia"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                    // paddingRight: "20px",
                  }}
                >
                  MultiMedia
                </NavLink>
              </li>
              <li className="nav-item pe-5 navmiddle">
                <NavLink
                  to={"/faqs"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                    // paddingRight: "20px",
                  }}
                >
                  FAQs
                </NavLink>
              </li>
              <li className="nav-item pe-5 navmiddle">
                <NavLink
                  to={"/mandatory-disclosure"}
                  className={"nav-link"}
                  style={{
                    color: "#ffffff",
                    // paddingRight: "20px",
                  }}
                >
                  Public Mandatory Disclosure
                </NavLink>
              </li>
            </ul>
          </div>
          <a href=" https://www.actedu.in/" target="_blank">
            <img alt="school.png" src="images/logo/act.png" className="logo1" />
          </a>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
