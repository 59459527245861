import React from "react";
import Seo from "../global/Seo";
import Faqsmodule from "../modules/Faqs";
import Heromodulefaqs from "../modules/Heromodulefaqs";

const objSeo = {
  title:
    "ACT Amrita International School: FAQs - Discover More About Our Program",
  keywords:
    "program, frequently asked questions, education, curriculum, admission, holistic development",
  pathSlug: "faqs",
  description:
    "Welcome to the FAQ page of our esteemed institution. Here, you'll find answers to common questions about our comprehensive curriculum, admission process, and how we foster holistic development in students. Explore the information below to gain a deeper understanding of what sets our education apart.",
};
const Faqs = () => {
  return (
    <>
      <Seo data={objSeo} />

      <Heromodulefaqs></Heromodulefaqs>
      <Faqsmodule></Faqsmodule>
    </>
  );
};

export default Faqs;
