import Form from "./Contactforms";
import "./Threecolumn.css";
const Threecolumn = (props) => {
  return (
    <>
      <div className="container-fluid mt-3git">
        {/* <div className="container text-center"> */}
        <div className="row ">
          <div className="col-md-4 px-5">
            <h5 className="sepl-primary-dark "> </h5>

            <p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3832.079767684786!2d75.62388017500685!3d16.16483628452854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc77995417da26f%3A0x76af492496c0e2ee!2sAct Amrita International School!5e0!3m2!1sen!2sin!4v1690266745015!5m2!1sen!2sin"
                className="school-location"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </p>
          </div>

          <div className="col-md-4 px-5 p-md-0 meetus">
            <h2 className="sepl-primary-dark">Meet Us </h2>
            <p>
              <i
                className="bi bi-telephone d-inline pe-1"
                style={{ color: "#011c02" }}
              ></i>
              070262 22296
            </p>
            <p>
              <i className="bi bi-at pe-1" style={{ color: "#011c02" }}></i>
              principalactamritaschool@gmail.com
            </p>
            <p>
              <i
                className="bi bi-geo-alt pe-1"
                style={{ color: "#011c02" }}
              ></i>
              ACT Amrita International school, Dr H M Shirur Campus Sector
              no-110,Road no 17 Navanagar, Bagalkot-587104, Bagalkot, India,
              Karnataka
            </p>
          </div>
          <div className="col-md-4 px-5">
            <h2 className="sepl-primary-dark">Contact Us</h2>
            <Form></Form>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Threecolumn;
