import "./TextOnly.css";
const TextOnly = (props) => {
  return (
    <div className="textonlycontainer text-center">
      <h1 className="title">{props.title}</h1>
      <p className="description">{props.description}</p>
    </div>
  );
};
export default TextOnly;
