import "./Whatsapp.css";
const Whatsapp = (props) => {
  return (
    <div className="iconcontainer">
      <div className="icon-bar">
        <a href="https://api.whatsapp.com/send?phone=917026222296">
          <span
            id="boot-icon"
            className="bi bi-whatsapp green-color"
            style={{ fontSize: 40 }}
          ></span>
        </a>
      </div>
    </div>
  );
};
export default Whatsapp;
