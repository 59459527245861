import React from "react";
import Seo from "../global/Seo";
import RegistrationList from "../modules/RegistrationList";
import HeroModuleRegistration from "../modules/HeroModuleRegistration";

const objSeo = {
  title: "ACT Amrita International School: Registration - Enroll Now",
  keywords:
    "Registration, program, enrollment, admissions, education, application form,ACT Amrita international school, Bagalkot",
  pathSlug: "registration",
  description:
    "Begin your journey towards an exceptional education by registering for our renowned Program. This is your opportunity to secure a spot in a curriculum designed to foster holistic development, critical thinking, and creativity. Our registration process is simple and streamlined, ensuring that you can take the first step towards a brighter future with ease. ",
};
const Registration = () => {
  return (
    <>
      <Seo data={objSeo} />
      <HeroModuleRegistration imagesrc="/images/registration/registration.jpg" />
      <RegistrationList />
    </>
  );
};

export default Registration;
