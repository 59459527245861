import React from "react";
import Montagepics from "../modules/Montagepics";
import Seo from "../global/Seo";

const objSeo = {
  title:
    "ACT Amrita International School: In the News - Our Educational Institution in Media Spotlight",
  keywords:
    "In the news, media coverage, educational institution, program, achievements, recognition,ACT Amrita international school, Bagalkot",
  pathSlug: "inmedia",
  description:
    "Discover the buzz surrounding our educational institution as we take center stage in media coverage. This page is your gateway to staying updated on our Program's accomplishments, accolades, and recognition. ",
};
const InMedia = () => {
  return (
    <>
      <Seo data={objSeo} />
      <Montagepics></Montagepics>
    </>
  );
};

export default InMedia;
