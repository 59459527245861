import React from "react";

const HeroModuleRegistration = (props) => {
  return (
    <div>
      <img
        src={props.imagesrc}
        alt="photo"
        style={{ width: "100%", maxHeight: "85vh" }}
      />
    </div>
  );
};
export default HeroModuleRegistration;
