import Seo from "../global/Seo";
import Threecolumn from "../modules/Threecolumn";
import Heromoduleonecontactus from "../modules/Heromoduleonecontactus";

const objSeo = {
  title: "ACT Amrita International School: Contact Us- Get in Touch",
  keywords:
    "Contact us, program, communication, inquiry, educational institution,ACT Amrita international school, Bagalkot",
  pathSlug: "contactus",
  description:
    "Connecting with us has never been easier. Use our Contact Us page to reach out for inquiries, information, or to share your thoughts about our Programs. Our dedicated team is here to assist you, whether you're a prospective student, parent, or community member. ",
};

const Contactus = () => {
  return (
    <>
      <Seo data={objSeo} />

      <Heromoduleonecontactus></Heromoduleonecontactus>
      <Threecolumn></Threecolumn>
    </>
  );
};

export default Contactus;
